import React from "react";
import pageImage from "images/HomepageLogo.png";
import {
  FullBackground,
  HowECLVideo,
  HowECLWorksNav,
  Layout,
  SEO,
} from "components";
import { graphql } from "gatsby";

const RunPage = ({ data, location }) => {
  const title = "Best In-Class Laboratory Automation at Your Fingertips";
  const description =
    "Emerald remotely conducts your experiments to your exact specifications";
  return (
    <FullBackground gradient>
      <HowECLWorksNav section={location && location.pathname} />
      <Layout clearNav clearFooter location={location}>
        <SEO
          image={pageImage}
          title={title}
          uri={location.pathname}
          description={description}
        />
        <HowECLVideo data={data.section} />
      </Layout>
    </FullBackground>
  );
};

export default RunPage;

export const query = graphql`
  query HowECLWorksSectionRun {
    section: howEclWorksJson(section: { eq: "run" }) {
      title
      videoURL
      paragraph
      btnText
      nextRoute
      controls {
        controls
      }
      bookmarks {
        text
        time
      }
    }
  }
`;
